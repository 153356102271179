<template>
  <v-container v-if="!loading">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <span>Reference: {{ order.reference }}</span>
            <v-spacer/>
            <v-btn
                color="info"
                :disabled="!order.label || !order.commercial_invoice"
                @click="downloadDocumentsBatch">
              Download label + CI
              <v-icon
                  right
                  dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
            <v-btn
                color="info"
                :disabled="!order.label"
                class="ml-3"
                @click="downloadLabel">
              Download Label
              <v-icon
                  right
                  dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
            <v-btn
                color="info"
                :disabled="!order.commercial_invoice"
                class="ml-3"
                @click="downloadCommercialInvoice">
              Download Commercial invoice
              <v-icon
                  right
                  dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
            <v-btn color="blue-grey" class="ml-3 white--text" :to="{ name: 'Shipment tracking', params: { id: order.id } }">
              Track
              <v-icon
                  right
                  dark
              >
                mdi-map-marker
              </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <v-sheet class="mx-2 rounded-b px-10 py-5 shipment-summary">
          <v-row>
            <v-col cols="8">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>Status: </td>
                    <td class="text-right">
                      <v-chip
                          label
                          :color="orderStatuses.find((item) => item.value === order.status).color"
                          class="summary-shipment-status px-10">
                        {{ orderStatuses.find((item) => item.value === order.status).title }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td>Date: </td>
                    <td class="text-right">{{ order.ship_date | date('LL') }}</td>
                  </tr>
                  <tr>
                    <td>Tracking number: </td>
                    <td class="text-right">{{order.tracking_number}}</td>
                  </tr>
                  <tr>
                    <td>Customer reference: </td>
                    <td class="text-right">{{ order.customer_reference }}</td>
                  </tr>
                  <tr>
                    <td>Description: </td>
                    <td class="text-right">{{ order.shipment.description }}</td>
                  </tr>
                  <tr>
                    <td>Crated at: </td>
                    <td class="text-right">{{ order.created | date }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-img
                  :src="courierLogos[order.selected_rate.shipper_account.courier]"
                  height="96"
                  contain
                  class="mx-auto"/>
              <v-simple-table class="mx-10">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>Service: </td>
                    <td class="text-right">{{ order.selected_rate.service_name }}</td>
                  </tr>
                  <tr>
                    <td>Transit days: </td>
                    <td class="text-right">{{ order.selected_rate.transit_days }}</td>
                  </tr>
                  <tr>
                    <td>Discount: </td>
                    <td class="text-right">0 {{'GBP' | currency}}</td>
                  </tr>
                  <tr>
                    <td>Total cost: </td>
                    <td class="text-right">{{ order.selected_rate.total_cost }} {{ order.selected_rate.cost_currency | currency}}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Source
          </v-card-title>
          <v-card-text>
            <v-simple-table class="mx-10">
              <template v-slot:default>
                <tbody>
                <tr>
                  <td>Country: </td>
                  <td class="text-right">{{ shipFromCountry.title }}</td>
                </tr>
                <tr>
                  <td>City: </td>
                  <td class="text-right">{{ order.shipment.ship_from.city }}</td>
                </tr>
                <tr>
                  <td>Postal code: </td>
                  <td class="text-right">{{ order.shipment.ship_from.postal_code}}</td>
                </tr>
                <tr>
                  <td>Contact name: </td>
                  <td class="text-right">{{ order.shipment.ship_from.contact_name}}</td>
                </tr>
                <tr>
                  <td>Company: </td>
                  <td class="text-right">{{ order.shipment.ship_from.company_name}}</td>
                </tr>
                <tr>
                  <td>Email: </td>
                  <td class="text-right">{{ order.shipment.ship_from.email}}</td>
                </tr>
                <tr>
                  <td>Phone: </td>
                  <td class="text-right">{{ order.shipment.ship_from.phone}}</td>
                </tr>
                <tr>
                  <td>Address: </td>
                  <td class="text-right">{{ order.shipment.ship_from.street1}} {{ order.shipment.ship_from.street2}} {{ order.shipment.ship_from.street3}} </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            Destination
          </v-card-title>
          <v-card-text>
            <v-simple-table class="mx-10">
              <template v-slot:default>
                <tbody>
                <tr>
                  <td>Country: </td>
                  <td class="text-right">{{ shipToCountry.title }}</td>
                </tr>
                <tr>
                  <td>City: </td>
                  <td class="text-right">{{ order.shipment.ship_to.city }}</td>
                </tr>
                <tr>
                  <td>Postal code: </td>
                  <td class="text-right">{{ order.shipment.ship_to.postal_code}}</td>
                </tr>
                <tr>
                  <td>Contact name: </td>
                  <td class="text-right">{{ order.shipment.ship_to.contact_name}}</td>
                </tr>
                <tr>
                  <td>Company: </td>
                  <td class="text-right">{{ order.shipment.ship_to.company_name}}</td>
                </tr>
                <tr>
                  <td>Email: </td>
                  <td class="text-right">{{ order.shipment.ship_to.email}}</td>
                </tr>
                <tr>
                  <td>Phone: </td>
                  <td class="text-right">{{ order.shipment.ship_to.phone}}</td>
                </tr>
                <tr>
                  <td>Address: </td>
                  <td class="text-right">{{ order.shipment.ship_to.street1}} {{ order.shipment.ship_to.street2}} {{ order.shipment.ship_to.street3}} </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card-title>
          Packages
        </v-card-title>
        <v-card v-for="(item, index) in order.shipment.parcels" :key="index" class="mb-5">
          <v-card-subtitle>
            Package #{{index + 1}} {{ parseInt(item.width) }}x{{ parseInt(item.depth) }}x{{ parseInt(item.height) }} {{ item.weight }}
          </v-card-subtitle>
          <v-card-text>
            {{ item.description }}
          </v-card-text>
          <v-card-text>
            <v-simple-table class="mx-10">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Country of manufacture</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(packageItem, index) in item.items" :key="index">
                    <td>{{ packageItem.description }}</td>
                    <td>{{ packageItem.quantity}}</td>
                    <td>{{ packageItem.price }}{{ packageItem.price_currency | currency }}</td>
                    <td>{{ (parseFloat(packageItem.price) * parseInt(packageItem.quantity)).toFixed(2) }}{{ packageItem.price_currency | currency }}</td>
                    <td>{{ getCountry(packageItem.origin_country).title }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col>
        Loading...
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {orderStatuses} from "@/choices";
import {courierLogos} from "@/choices";
import Country from "@/models/Country";
import Order from "@/models/Order";
import {BACKEND_URL} from "../../../config";

export default {
  name: 'ShipmentDetails',

  data() {
    return {
      loading: true,
      orderStatuses,
      courierLogos
    }
  },

  computed: {
    order() {
      return Order.find(this.$route.params.id)
    },
    shipFromCountry() {
      return Country.find(this.order.shipment.ship_from.country)
    },
    shipToCountry() {
      return Country.find(this.order.shipment.ship_to.country)
    },
    countries() {
      return Country.all()
    }
  },

  methods: {
    getCountry(code) {
      return Country.find(code);
    },
    downloadLabel() {
      const url = `${BACKEND_URL}/api/v1/shipping/orders/${this.order.id}/label/`
      window.open(url)
    },
    downloadCommercialInvoice() {
      const url = `${BACKEND_URL}/api/v1/shipping/orders/${this.order.id}/commercial_invoice/`
      window.open(url)
    },
    downloadDocumentsBatch() {
      const url = `${BACKEND_URL}/api/v1/shipping/orders/${this.order.id}/documents_batch/`
      window.open(url)
    }
  },

  async created() {
    await Order.api().get(`/api/v1/shipping/orders/${this.$route.params.id}?expand=shipment,shipment.parcels,shipment.parcels.items,shipment.ship_to,shipment.ship_from,selected_rate,selected_rate.shipper_account`);
    this.loading = false;
  }
}
</script>

<style scoped>
  tr:hover {
    background-color: transparent !important;
  }
</style>